<template>
  <div>
    <v-expand-transition>
      <v-row v-show="showFilter && isOpenedFilter" class="mb-1">
        <v-col sm="12">
          <v-card>
            <v-card-title>
                Filtres              
                <v-spacer/>
                
                <v-btn small fab color="error" @click="onClickBtnCancelFilter"><v-icon>mdi-filter-remove</v-icon></v-btn>
                <v-btn small fab color="success" class="ml-2" @click="onClickBtnApplyFilter"><v-icon >mdi-filter-check</v-icon></v-btn>              
            </v-card-title>
            <v-card-text v-if="isOnlyOneCustomer">     
              <v-row>               
                <v-col md="6">
                  <v-radio-group
                    v-model="userFilters.vehicleStatus"
                    column
                  >
                    <v-radio
                      label="Tous les véhicules"
                      color="primary"
                      value="-1"
                    ></v-radio>
                    <v-radio
                      label="Vehicules en commande"
                      color="primary"
                      value="0"
                    ></v-radio>
                    <v-radio
                      label="Véhicules en parc"
                      color="primary"
                      value="1"
                    ></v-radio>
                    <v-radio
                      label="Véhicules hors parc"
                      color="primary"
                      value="2"
                    ></v-radio>
                    
                  </v-radio-group>
                </v-col>      
                <v-col md="6" class="align-self-center">
                  <v-radio-group
                    v-model="userFilters.leaseStatus"
                    column
                  >
                    <v-radio
                      label="Tous les contrats"
                      color="primary"
                      value="0"
                    ></v-radio>
                    <v-radio
                      label="Contrats en renouvellement"
                      color="primary"
                      value="3"
                    ></v-radio>
                    <v-radio
                      label="Contrats à renouveller"
                      color="primary"
                      value="1"
                    ></v-radio>
                    <v-radio
                      label="Contrats echus"
                      color="primary"
                      value="2"
                    ></v-radio>
                  </v-radio-group>
                </v-col>    
              </v-row>     
              <v-row>                                          
                <v-col md="2" class="align-self-center">
                  <v-subheader class="mb-5">
                    Mise en circulation
                  </v-subheader>
                  <v-range-slider                       
                    v-model="userFilters.yearVehicleRange"                    
                    :min="minYearVehicle"
                    :max="maxYearVehicle"
                    thumb-label="always"
                  />
                </v-col>

                <v-col md="2" class="align-self-center">
                  <v-subheader class="mb-5">
                    Kilométrage annuel
                  </v-subheader>
                  <v-range-slider                                      
                    v-model="userFilters.distanceRange"                    
                    min="0"
                    max="100000"                    
                    step="1000"
                    thumb-label="always"
                  />
                </v-col>

                <v-col md="2" class="align-self-center">
                  <v-subheader class="mb-5">
                    Loi de roulage
                  </v-subheader>
                  <v-range-slider                                    
                    v-model="userFilters.rollingLawRange"                    
                    min="0"
                    max="200"                    
                    step="10"
                    thumb-label="always"
                  />
                </v-col>

                <v-col md="2" class="align-self-center">
                  <v-subheader class="mb-5">
                    Kilométrage contractuel annuel
                  </v-subheader>
                  <v-range-slider                                  
                    v-model="userFilters.leaseDistanceRange"                    
                    min="0"
                    max="100000"                    
                    step="1000"
                    thumb-label="always"
                  />
                </v-col>

                <v-col md="2" class="align-self-center">
                  <v-subheader class="mb-5">
                    Durée du contrat
                  </v-subheader>
                  <v-range-slider                        
                    v-model="userFilters.leaseDurationRange"                    
                    min="0"
                    max="72"                    
                    step="12"
                    thumb-label="always"
                  />
                </v-col>                               
              </v-row>              
            </v-card-text>

            <v-card-text v-else>              
              <v-row>
                  <v-col md="10" class="align-self-center">
                    <div class="d-flex flex-no-wrap">
                      <div v-if="imgCustomerLogo">
                        <v-avatar tile size="52">
                          <v-img 
                            :src="imgCustomerLogo" 
                            max-width="80"
                          />
                        </v-avatar>
                      </div>
                      <div v-if="!isOnlyOneCustomer" style="width: 100%"> 
                        <v-select       
                          class="pl-2"               
                          v-model="userFilters.customer"
                          :items="customers"
                          item-text="name"
                          item-value="id"                       
                          label="Client"                                                
                        ></v-select>
                      </div>
                    </div>
                  </v-col>

                  <v-col md="2">
                    <v-radio-group
                      v-model="userFilters.vehicleStatus"
                      column
                    >
                      <v-radio
                        label="Tous les véhicules"
                        color="primary"
                        value="-1"
                      ></v-radio>
                      <v-radio
                        label="Vehicules en commande"
                        color="primary"
                        value="0"
                      ></v-radio>
                      <v-radio
                        label="Véhicules en parc"
                        color="primary"
                        value="1"
                      ></v-radio>
                      <v-radio
                        label="Véhicules hors parc"
                        color="primary"
                        value="2"
                      ></v-radio>
                      
                    </v-radio-group>
                  </v-col>            

                  <v-col md="2" class="align-self-center">
                    <v-subheader class="mb-5">
                      Mise en circulation
                    </v-subheader>
                    <v-range-slider                       
                      v-model="userFilters.yearVehicleRange"                    
                      :min="minYearVehicle"
                      :max="maxYearVehicle"
                      thumb-label="always"
                    />
                  </v-col>

                  <v-col md="2" class="align-self-center">
                    <v-subheader class="mb-5">
                      Kilométrage annuel
                    </v-subheader>
                    <v-range-slider                                      
                      v-model="userFilters.distanceRange"                    
                      min="0"
                      max="100000"                    
                      step="1000"
                      thumb-label="always"
                    />
                  </v-col>

                  <v-col md="2" class="align-self-center">
                    <v-subheader class="mb-5">
                      Loi de roulage
                    </v-subheader>
                    <v-range-slider                                    
                      v-model="userFilters.rollingLawRange"                    
                      min="0"
                      max="200"                    
                      step="10"
                      thumb-label="always"
                    />
                  </v-col>

                  <v-col md="2" class="align-self-center">
                    <v-subheader class="mb-5">
                      Kilométrage contractuel annuel
                    </v-subheader>
                    <v-range-slider                                  
                      v-model="userFilters.leaseDistanceRange"                    
                      min="0"
                      max="100000"                    
                      step="1000"
                      thumb-label="always"
                    />
                  </v-col>

                  <v-col md="2" class="align-self-center">
                    <v-subheader class="mb-5">
                      Durée du contrat
                    </v-subheader>
                    <v-range-slider                        
                      v-model="userFilters.leaseDurationRange"                    
                      min="0"
                      max="72"                    
                      step="12"
                      thumb-label="always"
                    />
                  </v-col>

                  <v-col md="2" class="align-self-center">
                    <v-radio-group
                      v-model="userFilters.leaseStatus"
                      column
                    >
                      <v-radio
                        label="Tous les contrats"
                        color="primary"
                        value="0"
                      ></v-radio>
                      <v-radio
                        label="Contrats en renouvellement"
                        color="primary"
                        value="3"
                      ></v-radio>
                      <v-radio
                        label="Contrats à renouveller"
                        color="primary"
                        value="1"
                      ></v-radio>
                      <v-radio
                        label="Contrats echus"
                        color="primary"
                        value="2"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>                
              </v-row>            
            </v-card-text>         
          </v-card>         
        </v-col>       
      </v-row>
    </v-expand-transition>


    <v-card elevation="0">
      <v-card-title>              
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Rechercher"
          single-line
          hide-details
          clearable
        ></v-text-field>
                
        <v-btn   
            class="mt-5 mx-2"
            v-if="downloadBtn"          
            icon 
            elevation="2"
            @click="onClickBtnExport()"
            title="Exporter la sélection">
              <v-icon>mdi-download</v-icon>
          </v-btn>      
      </v-card-title>
          
      <v-card-text >
        <v-data-table        
          v-model="selectedItems"
          :headers="headers"
          :items="items"
          :search="search"
          :single-select="singleSelect"
          fixed-header
          show-select        
          item-key="id"      
          :loading="isLoading"
          loading-text="Chargement en cours..."
          locale="fr-FR"               
          :items-per-page="items.length > 100 ? 15 : -1"
          @item-selected="onItemSelected"    
          @click:row="onClickRow"   
          @toggle-select-all="onToggleSelectAll"
        >      

            <template v-slot:group.header="{group, items, isOpen, toggle}">
              <th colspan="9" class="subtitle-2">
                <v-icon @click="toggle" class="mr-3"
                  >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                </v-icon>
                {{ group }}
              </th>
            </template>            

            <template v-slot:item.maker="{ item }">
              <div class="d-flex flex-no-wrap align-center">
                <v-img max-width="48" :src="item.maker_image"/>                       
                <div>{{ item.maker }}</div>
              </div>
            </template>   

            <template v-slot:item.rollinglaw="{ item }">
              <v-chip
                small
                :color="getColor(item.rollinglaw)"
                dark
              >
                {{ item.rollinglaw }}%
              </v-chip>        
            </template> 

            <template v-slot:item.consumption_ratio="{ item }">              
              <v-chip
                v-if="item.consumption_ratio != ''"
                small
                :color="getColor(item.consumption_ratio)"
                dark
              >
                {{ item.consumption_ratio }}% - {{ item.consumption_avg }} l
              </v-chip>                 
              <span v-else v-show="(item.consumption_avg > 0)">{{ item.consumption_avg }} l</span>              
            </template> 

            <template v-slot:item.lease_closure="{ item }">
              <v-chip
                v-if="item.lease_closure !== undefined && item.lease_closure !== ''"
                small
                :color="item.lease_color"
                dark
              >
                {{ item.lease_closure }}
              </v-chip>        
            </template>  

            <template v-slot:item.leaseStatusLabel="{ item }">              
              <v-chip
                v-if="item.lease_status != 0 && item.leaseStatusLabel != ''"
                small
                :color="item.lease_status_color"
                dark
              >
                {{ item.leaseStatusLabel }}
              </v-chip>        
            </template>     

            <template v-slot:item.vehicle_status_label="{ item }">
              <v-chip
                v-if="item.status !== 1"
                small
                :color="item.status_color"
                dark
              >
                {{item.status_label}}
              </v-chip>                  
            </template>    

              <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">                                              
                {{pageStart}}-{{pageStop}} sur {{itemsLength}}
              </template>        
        </v-data-table>
      </v-card-text>
    </v-card>    
  </div>                  
</template>

<script>
import { mapGetters } from 'vuex'
import Functions from "@/js/functions.js"
 
export default {
    name: "AVehicleList",  
    props: {
      singleSelect: {
        type: Boolean,
        default: false
      },
      small: {
        type: Boolean,
        default: false
      },
      defaultSearch: {
        type: String,
        default: ''
      },
      models: {
        type: String,
        default: ''
      },
      makers: {
        type: String,
        default: ''
      },
      years: {
        type: String,
        default: ''
      },
      distance: {
        type: String,
        default: ''
      },
      distanceReal: {
        type: String,
        default: ''
      },
      distanceContract: {
        type: String,
        default: ''
      },
      customer: {
        type: Number,
        default: 0
      },
      consumption: {
        type: String,
        default: ''
      },
      rollingLaw: {
        type: String,
        default: ''
      },      
      status: {
        type: String,
        default: "none"
      },
      downloadBtn: {
        type: Boolean,
        default: false
      },
      showFilter: {
        type: Boolean,
        default: false      
      },
      cache: {
        type: Boolean,
        default: false
      }
    }, 
    data() {
      return {
        isLoading: false,        
        filters: {},   
        isOpenedFilter: false,
        userFilters: {
          customer: 0,                    
          yearVehicleRange: [0, 0],
          distanceRange: [0, 0],
          leaseDistanceRange: [0, 0],
          leaseDurationRange: [0, 0],
          leaseStatus: "0",
          vehicleStatus: "0",
          rollingLawRange: [0, 0],          
        },          
        headersSmall: [         
          {text: "Immatriculation", value: "registration", groupable: false},
          {text: "Marque", value: "maker"},
          {text: "Model", value: "model"},
          {text: "Conducteur", value: "driver"},
          {text: "Compte client", value: "customer"},
        ],
        headersMedium: [          
          {text: "Immatriculation", value: "registration", groupable: false},
          {text: "Marque", value: "maker"},
          {text: "Model", value: "model"},
          {text: "Conducteur", value: "driver"},
          {text: "Compte client", value: "customer", align:'center'},
          {text: "Agence", value: "agency", align:'center'},
          {text: "Kilométrage", value: "distance", groupable: false, align:"end"},
          {text: "Fournisseur", value: "lease_provider", groupable: false},   
          {text: "Km contractuel", value: "lease_distance", groupable: false, align:"end"},   
          {text: "Durée contractuel", value: "lease_duration", groupable: false, align:"end"},                    
          {text: "Loi de roulage", value: "rollinglaw", groupable: false, align:'center'},
          {text: "Consommation", value: "consumption_ratio", groupable: false, align:"center"},
          {text: "Projection en Km", value: "projection_kms", groupable: false, align:'end'},
          {text: "Projection en durée", value: "projection_duration", groupable: false, align:'end'},
          {text: "Echéance contrat", value: "lease_closure", groupable: false, align:'center'},
          {text: "Statut contrat", value: "leaseStatusLabel", groupable: false, align:'center'},
          {text: "Statut véhicule", value: "vehicle_status_label", groupable: false, align:'center'},
        ],
        selectedItems: [],
        items: [],
        search: '',
        headers: this.headersMedium,
        vehicles: [],
        customers: [],
        minYearVehicle: new Date().getFullYear() - 10,
        maxYearVehicle: new Date().getFullYear() + 1,                      
      }    
    },
    methods: {
       get: function() {  
         this.isLoading = true          
          if (this.cache && this.preLoadVehicles.length) {            
              this.items = this.preLoadVehicles 
              this.isLoading = false                                    
          }

        let filters = 'filters'
        
        Object.entries(this.filters).forEach(el => { 
          if (el[1] !== '') filters += `&${el[0]}=${el[1]}` 
        })
        
        let url = `vehicles`      
        if (this.customer !== 0) url += `/customer/${this.customer}`
        if (filters !== '') url += '?' + filters

        this.$http
            .get(url)            
            .then(response => {                        
                if (response.status == 200) {      
                  this.vehicles = response.data         
                  this.prepareListItems()
                  if (this.cache) this.$store.commit('setPreLoadVehicles', this.items)    
                }   
                else this.listItems = [];
                this.isLoading = false 
            })
            .catch(error => {     
              console.log(error)           
                this.isLoading = false
                if (error.response.status === 401) this.$root.$emit("logout"); 
                else this.$root.$emit("serverError", error);     
            });
      },
      getCustomers: async function() {
        try {
          let url = `customers`
          let response = await this.$http.get(url)            
                              
          if (response.status == 200) this.customers = [{id: 0, name: ''}, ...response.data]                        
        }
        catch (error) {                            
            if (error.response.status === 401) this.$root.$emit("logout")
            else this.$root.$emit("serverError", error)
        }                  
      },
      getSelectedItems: function() {
        return this.selectedItems
      },
      resetSelectedItems: function() {
        this.selectedItems = []
      },
      prepareListItems: function() {        
        this.items = this.vehicles.map((el) => { 
          let consumption = el.consumption_mixte ?? 0
          if (consumption == 0 && el.model) consumption = el.model.consumptions ? el.model.consumptions.mixte : 0

          let obj =  {
            id: el.id,
            fleet_code: el.fleet_code,
            registration: el.registration,
            serial_number: el.serial_number,
            maker: el.model ? el.model.maker.name : '',
            model: el.model ? el.model.name : '',
            distance: el.distance,
            driver_id: el.driver_id,
            driver: el.driver_id === null ? "" : `${el.driver.lastname} ${el.driver.firstname}`,
            driver_mail: el.driver_id === null ? "" : el.driver.mail,
            driver_matricule: el.driver_id === null ? "" : el.driver.user_number,
            driver_cost_id: el.driver_id === null ? "" : el.driver.cost_id,
            customer: el.customer ? el.customer.name : '',
            customer_image: el.customer ? `${this.$constants.application.resourcesURL}/images/${el.customer.image}` : '',
            agency: el.driver.agency ? el.driver.agency.name : '',            
            maker_image: el.model ? `${this.$constants.application.resourcesURL}/images/vehicles/${el.model.maker.name.toLowerCase().replace(' ', '_')}/logo.png` : '',
            last_mileage: el.last_mileage_date,
            last_audit: el.last_audit_date,
            lease_provider: el.lease === undefined ? "" : el.lease.provider.name,
            lease_reference: el.lease === undefined ? "" : el.lease.reference,
            lease_start: el.lease === undefined ? "" : el.lease.date_start,
            lease_duration: el.lease === undefined ? "" : el.lease.duration,
            lease_distance: el.lease === undefined ? "" : el.lease.distance,
            lease_closure: el.lease === undefined ? "" : el.lease.date_closure,
            lease_status: el.lease === undefined ? "" : el.lease.status,
            leaseIsRenewal: el.lease === undefined ? "" : el.lease.is_renewal,
            leaseRenewalStatus: el.lease === undefined ? "" : el.lease.renewal_status,            
            rollinglaw: el.rollinglaw,
            consumption_avg: el.consumption_avg,
            consummption_mixte: consumption,
            consumption_ratio: el.consumption_avg == 0 || consumption == 0 ? '' : Math.round(el.consumption_avg * 100 / consumption),
            projection_kms: el.lease === undefined ? 0 : Math.ceil(el.rollinglaw * el.lease.distance / 100),
            projection_duration: el.lease === undefined ?  0 : Math.ceil(el.lease.duration * 100 / el.rollinglaw),    
            status: el.status                                        
          }

          obj.status_label = Functions.Vehicles.getStatusLabel(el.status)
          obj.status_color = Functions.Vehicles.getStatusColor(el.status)                  

          if (el.lease !== undefined) {
            let rest = el.lease.duration - el.lease.since
            obj.lease_color = Functions.Leases.getClosureStatusColor(rest)
            obj.lease_status_color = Functions.Leases.getStatusColor(el.lease.status)
            if (obj.leaseIsRenewal) obj.leaseStatusLabel = this.listItems.lease_renewal_status.find(el => el.id == obj.leaseRenewalStatus).name
            else obj.leaseStatusLabel = Functions.Leases.getStatusLabel(el.lease.status)
          }                    
                                
          return obj
        })
      },
      makeFilter: function(useUserFilters = false) {
        this.filters = {}    
        if (useUserFilters) {                
          if (this.userFilters.customer) this.filters.cstms = this.userFilters.customer          
          if (this.userFilters.yearVehicleRange[0] !== this.userFilters.yearVehicleRange[1]) this.filters.years = this.userFilters.yearVehicleRange.join('-')
          if (this.userFilters.distanceRange[1]) this.filters.dist = this.userFilters.distanceRange.join('-')
          if (this.userFilters.leaseDistanceRange[1]) this.filters.dstc = this.userFilters.leaseDistanceRange.join('-')       
          if (this.userFilters.leaseDurationRange[1]) this.filters.durc = this.userFilters.leaseDurationRange.join('-')        
          if (this.userFilters.rollingLawRange[1]) this.filters.rlaw = this.userFilters.rollingLawRange.map(el => el / 100).join('-')  
          if (this.userFilters.leaseStatus !== "0") this.filters.lease = this.userFilters.leaseStatus
          if (this.userFilters.vehicleStatus !== "-1") this.filters.status = this.userFilters.vehicleStatus               
        }
        else {
          if (this.status !== 'none') this.filters.status = this.status
          if (this.models) this.filters.mdls = this.models
          if (this.makers) this.filters.mkrs = this.makers
          if (this.years) this.filters.years = this.years
          if (this.distance) this.filters.dist = this.distance
          if (this.distanceReal) this.filters.dstr = this.distanceReal
          if (this.distanceContract) this.filters.dstc = this.distanceContract
          if (this.customer) this.filters.cstms = this.customer
          if (this.consumption) this.filters.csmpt = this.consumption
          if (this.rollingLaw) this.filters.rlaw = this.rollingLaw
        }        
      },
      openFilter: function() {
        this.isOpenedFilter = true;
      },
      closeFilter: function() {
        this.isOpenedFilter = false;
      },
      toggleFilter: function() {
        this.isOpenedFilter = !this.isOpenedFilter;        
      },
      getColor: function(value) {
        if (value > 120) return 'red'
        else if (value >= 80) return 'green'
        else return 'orange'
      },
      onItemSelected: function(event) {
        this.$emit('itemSelected', event)
        this.$nextTick(() => {this.$emit('change', this.selectedItems)})
      },
      deleteItems: function(ids) {
        this.items = this.items.filter(el => ids.indexOf(el.id) == -1)  
      },
      onToggleSelectAll: function(event) {
        this.$emit('toggleSelectAll', event)
        this.$nextTick(() => {this.$emit('change', this.selectedItems)})
      }, 
      onClickRow: function(event) {
        this.$emit('clickRow', event)
      },
      onClickBtnExport: function() {
        let exports = [];
        if (this.selectedItems.length == 0) {
          exports = this.vehicles
        }
        else {
           exports = this.vehicles.filter(el => {
            return this.selectedItems.find(itm => el.id == itm.id) !== undefined
          })        
        }
          
        exports = exports.map(el => {
          let gender, energy, item, consumptionCity, consumptionMixte, consumptionHighway, co2
          
          if (el.model) {
            item = this.$constants.vehicleModels.FAMILIES.find(item => item.id == el.model.family)
            if (item !== undefined) gender = item.label
          }

          if (el.modification) {
            item = this.$constants.vehicleModifications.ENERGIES.find(item => item.id == el.modification.family)
            if (item !== undefined) energy = item.label

            consumptionCity = el.modification.consumptions ? el.modification.consumptions.city : '';
            consumptionMixte = el.modification.consumptions ? el.modification.consumptions.mixte : '';
            consumptionHighway = el.modification.consumptions ? el.modification.consumptions.highway : '';
            co2 = el.modification.co2;
          }

          consumptionCity = el.consumption_city ? el.consumption_city : consumptionCity;
          consumptionMixte = el.consumption_mixte ? el.consummption_mixte : consumptionMixte;
          consumptionHighway = el.consumption_highway ? el.consumption_highway : consumptionHighway; 
          co2 = el.co2 ? el.co2 : co2;

          return {     
            customer: el.customer ? el.customer.name : '',                        
            id: el.id,
            registration: el.registration,
            serial_number: el.serial_number,
            maker: el.model ? el.model.maker.name : '',
            model: el.model ? el.model.name : '',
            motorization: el.modification ? el.modification.name : '',
            serie: el.model ? el.model.serie : '',
            circulation_date: el.circulation_date,
            fleet_code: el.fleet_code,
            car_policy: el.driver.car_policy,
            gender: gender,
            energy: energy,
            consumption_city: consumptionCity,              
            consumption_mixte: consumptionMixte,
            consumption_highway: consumptionHighway,
            consumption_avg: el.consumption_avg,
            co2: co2,
            distance: el.distance,
            distance_date: this.$moment(Date.parse(el.last_mileage_date)).format("YYYY-MM-DD"),
            driver: el.driver_id === null ? "" : `${el.driver.lastname} ${el.driver.firstname}`,
            driver_mail: el.driver_id === null ? "" : el.driver.mail,
            driver_matricule: el.driver_id === null ? "" : el.driver.user_number,
            driver_cost_id: el.driver_id === null ? "" : el.driver.cost_id,
            driver_agency: el.driver.agency ? el.driver.agency.name : '',  
            lease_reference: el.lease === undefined ? "" : el.lease.reference,
            lease_start: el.lease === undefined ? "" : el.lease.date_start,
            lease_duration: el.lease === undefined ? "" : el.lease.duration,
            lease_distance: el.lease === undefined ? "" : el.lease.distance,
            lease_closure: el.lease === undefined ? "" : el.lease.date_closure,              
            rollinglaw: el.rollinglaw,
            price: el.price,
            discounted_price: el.discounted_price,
            options_price: el.options_price,
            rent: el.lease !== undefined && el.lease.has_rent ? el.lease.rent : '',
            maintenance_rent: el.lease !== undefined && el.lease.has_maintenance_rent ? el.lease.maintenance_rent : '',
            tires_rent: el.lease !== undefined && el.lease.has_tires_rent ? el.lease.tires_rent : '',
            assistance_rent: el.lease !== undefined && el.lease.has_assistance_rent ? el.lease.assistance_rent : '',
            insurance_rent: el.lease !== undefined && el.lease.has_insurance_rent ? el.lease.insurance_rent : '',
            other_rent: el.lease !== undefined && el.lease.has_other_rent ? el.lease.other_rent : '',
            rental_provider: el.lease === undefined || el.lease.provider == undefined ? '' : el.lease.provider.name
          }            
        })
                        
        Functions.Resources.exportData(Object.keys(exports[0]), exports.map(el => Object.values(el)), ';', 'export')          
        
      },
      onClickBtnCancelFilter: function() {
        this.filters = {}
        this.userFilters = {
          customer: 0,                    
          yearVehicleRange: [0, 0],
          distanceRange: [0, 0],
          leaseDistanceRange: [0, 0],
          leaseDurationRange: [0, 0],
          leaseStatus: "0",
          vehicleStatus: "-1",
          rollingLawRange: [0, 0]         
        }               
      },
      onClickBtnApplyFilter: function() {
        this.isOpenedFilter = false
        this.items = []
        this.$store.commit('setPreLoadVehicles', [])
        this.$store.commit('setVehicleFilters', this.userFilters)
        this.makeFilter(true)
        this.get()        
      }
    },
    watch: {
     
    },
    computed: {
      ...mapGetters([
        'preLoadVehicles',
        'vehicleFilters',
        'listItems'
      ]),
      imgCustomerLogo: function() {
        let c = this.customers.find(el => el.id == this.userFilters.customer)
        if (c !== undefined) {
          let img = c.image
          if (img) return `${this.$constants.application.resourcesURL}/images/${img}`
          else return undefined
        }
        else return undefined
      },
      isFilterApplied: function() {
        return Object.values(this.vehicleFilters).length
      },
      isOnlyOneCustomer: function() {
        return this.customers.length <= 2;
      },
    },
    async created() {    
      this.$store.dispatch('getListItems', 'lease_renewal_status'); 

      await this.getCustomers()
      
      if (this.cache) {
        if (this.isFilterApplied || this.isOnlyOneCustomer) {
          this.userFilters = {...this.vehicleFilters} 
          this.makeFilter(!this.isOnlyOneCustomer)
          this.get();
        }
        else this.isOpenedFilter = true
      }
      else {
        this.makeFilter()
        this.get()
      }

      if (this.small) this.headers = this.headersSmall
      else this.headers = this.headersMedium      
      
    }
}
</script>

<style lang="scss" scoped>
 
</style>